.singleProjectCard {
  margin-top: 20px;
  border: 0;
}

li {
  max-width: max-content;
  width: auto;
  overflow-x: auto;
}

.title {
  font-size: 28px;
  font-weight: 700;
}

.imageCard {
  margin-top: 10px;
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
