.passwordForm {
  text-align: center;
  margin: auto;
  max-width: 900px;
}

p {
  font-size: 18px;
  font-family: Poppins;
}
