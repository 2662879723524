.newIssueButton {
  color: #be0dcf;
  background-color: white;
  border-color: #be0dcf;
  margin-bottom: 30px;
}

.newIssueButton:hover {
  color: white;
  background-color: #be0dcf;
  transition: 0.2s;
}

.cancelNewIssueButton {
  color: black;
  background-color: rgb(255, 246, 246);
}

.cancelNewIssueButton:hover {
  color: black;
  background-color: white;
  transition: 0.2s;
}

.createNewIssueButton {
  color: white;
  background-color: #be0dcf;
}

.createNewIssueButton:hover {
  color: white;
  background-color: #be0dcf;
  transition: 0.2s;
}
