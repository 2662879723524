.list {
  font-size: 28px;
  font-weight: 700;
  list-style: none;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  line-height: 30px;
}

.tester {
  color: #be0dcf;
}

.img {
  height: 64px;
}

.navbar {
  height: 64px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.navImg {
  width: 5%;
  text-align: left;
}

.navTitle {
  width: 85%;
  text-align: left;
}

.navHome {
  width: 5%;
  text-align: left;
}

.navLogout {
  width: 5%;
  text-align: right;
}
