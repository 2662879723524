.newCommentBox {
  margin-top: 35px;
}

.backCommentsButton {
  color: black;
  background-color: rgb(255, 246, 246);
}

.backCommentsButton:hover {
  color: black;
  background-color: white;
  transition: 0.2s;
}

.addCommentButton {
  color: white;
  background-color: rgb(190, 13, 207);
}

.addCommentButton:hover {
  color: white;
  background-color: rgb(201, 72, 212);
  transition: 0.2s;
}

.editCommentButton {
  color: white;
  background-color: rgb(190, 13, 207);
  position: absolute;
  left: 0px;
  top: 62px;
}

.editCommentButton:hover {
  color: white;
  background-color: rgb(201, 72, 212);
  transition: 0.2s;
  position: absolute;
  left: 0px;
  top: 62px;
}

.saveCommentButton {
  color: white;
  background-color: rgb(190, 13, 207);
  position: absolute;
  left: 0px;
  top: 62px;
}

.saveCommentButton:hover {
  color: white;
  background-color: rgb(201, 72, 212);
  transition: 0.2s;
  position: absolute;
  left: 0px;
  top: 62px;
}

.deleteCommentButton {
  background-color: red;
  color: white;
  position: absolute;
  left: 59px;
  top: 62px;
}

.deleteCommentButton:hover {
  background-color: #ff5353;
  transition: 0.2s;
}

.editCommentBox {
  position: absolute;
  top: 23px;
}
