.blockButton {
  background-color: red;
  color: white;
}

.blockButton:hover {
  background-color: #ff3939;
  color: white;
  border-color: #ff3939;
  transition: 0.2s;
}

.unblockButton {
  background-color: rgb(79, 79, 255);
  color: white;
}

.unblockButton:hover {
  background-color: rgb(14, 129, 175);
  color: white;
  border-color: rgb(14, 129, 175);
  transition: 0.2s;
}

.adminDeleteButton {
  background-color: white;
}

.adminDeleteButton:hover {
  background-color: white;
  border-color: white;
}

.adminDeleteIcon {
  font-size: 23px;
  vertical-align: 2px;
  color: red;
}

.adminDeleteIcon:hover {
  font-size: 21px;
  vertical-align: 1px;
  color: #ff3939;
  transition: 0.2s;
}
