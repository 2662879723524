.viewButton {
  color: white;
  background-color: rgb(190, 13, 207);
}

.viewButton:hover {
  color: white;
  background-color: rgb(201, 72, 212);
  transition: 0.2s;
}

.editButton {
  color: white;
  background-color: rgb(190, 13, 207);
  position: absolute;
  right: 28px;
}

.editButton:hover {
  color: white;
  background-color: rgb(201, 72, 212);
  transition: 0.2s;
}

.deleteButton {
  background-color: red;
  color: white;
}

.deleteButton:hover {
  background-color: #ff5353;
  transition: 0.2s;
}

.commentsButton {
  color: white;
  background-color: rgb(190, 13, 207);
  margin-left: 7px;
  width: 35%;
  text-align: center;
  font-size: 14px;
}

.commentsButton:hover {
  color: white;
  background-color: rgb(201, 72, 212);
  margin-left: 7px;
  width: 35%;
  text-align: center;
  font-size: 14px;
  transition: 0.2s;
}

.saveEditIssueButton {
  color: white;
  background-color: rgb(190, 13, 207);
}

.saveEditIssueButton:hover {
  color: white;
  background-color: rgb(201, 72, 212);
  transition: 0.2s;
}

.cancelEditIssueButton {
  color: black;
  background-color: rgb(255, 246, 246);
}

.cancelEditIssueButton:hover {
  color: black;
  background-color: white;
  transition: 0.2s;
}

.descriptionLabel {
  position: absolute;
  top: 73px;
}

.descriptionText {
  max-width: 450px;
  word-wrap: break-word;
  position: absolute;
  top: 113px;
  left: 36px;
}
