.ant-card {
  font-family: "Poppins";
  font-size: 14px;
  border-radius: 20px;
}
.ant-btn-ghost {
  font-size: 12px;
  color: white;
  background-color: #be0dcf;
  margin-top: 10px;
}

.ant-btn-ghost:hover {
  color: #be0dcf;
  background-color: white;
  border-color: #be0dcf;
  transition: 0.2s;
}

.type {
  color: white;
  background-color: #be0dcf;
  border: 0;
}

.title-project {
  font-weight: bold;
  font-size: 18px;
  max-height: 100px;
  text-align: center;
  width: 250px;
}

.header {
  color: #be0dcf;
  font-weight: 700;
  font-size: 28px;
}

.description-project {
  height: 100px;
  overflow-x: auto;
  line-height: normal;
}

.project-list {
  height: 500px;
  max-width: 1200px;
  overflow-x: scroll;
  overflow-y: hidden;
}

ul {
  list-style: none;
  line-height: normal;
  padding-left: 0;
}

li {
  margin-bottom: 10px;
  max-width: min-content;
  width: auto;
  overflow: hidden;
}
